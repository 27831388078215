@use 'mixins' as *;
@use 'typo' as *;
@use 'space' as *;
@use 'colors' as *;
@use 'responsive' as *;

//general
html,
body {
  height: 100%;
}

h1 {
  @include head-xl;
}

h2 {
  @include head-lg;
}

h3 {
  @include head-md;
}

h4 {
  @include head-sm;
}

body {
  font-family: $font-family !important;
  font-size: $base-font-size !important;
  line-height: 1.42857143;
  color: #606060;
  margin: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

button {
  cursor: pointer;
}

p {
  margin-top: 0;
}

textarea {
  resize: none !important;
}

table {
  th {
    .mat-sort-header-container {
      @include flex-justify-center;
    }
  }
}

//wrap
.no-wrap {
  white-space: nowrap;
}

.wrap {
  white-space: pre-line;
}

//flex options
.center {
  text-align: center;
}

.row {
  @include flex-row;
  justify-content: space-between;
}

.column {
  @include flex-column;
}

.space-between {
  @include flex-justify-between;
}

//media & breakpoints
@media print {
  .dontPrint {
    visibility: hidden !important;
    display: none !important;
  }
}

@include breakpoint(print) {
  html {
    height: auto;
    scroll-behavior: smooth;
  }
}
