@use 'typo' as *;

// Title/ Header 1
@mixin head-xl {
  font-family: $font-family !important;
  font-weight: $semi-bold-font-weight !important;
  font-size: $h2-font-size !important;
}

// Heading Large
@mixin head-lg {
  font-family: $font-family !important;
  font-weight: $medium-font-weight !important;
  font-size: $h2-font-size !important;
  line-height: 125% !important;
}

// Heading Medium
@mixin head-md {
  font-family: $font-family !important;
  font-weight: $semi-bold-font-weight !important;
  font-size: $h3-font-size !important;
  line-height: 140% !important;
}

// Subtitle
@mixin head-sm {
  font-family: $font-family !important;
  font-weight: $medium-font-weight !important;
  font-size: $h4-font-size !important;
  line-height: 140% !important;
}

// Body
@mixin body-lg {
  font-family: $font-family !important;
  font-weight: $medium-font-weight !important;
  font-size: $h4-font-size !important;
  line-height: 150% !important;
}

// Body
@mixin body-md {
  font-family: $font-family !important;
  font-weight: $base-font-weight !important;
  font-size: $base-font-size !important;
  line-height: 104% !important;
}

// Body
@mixin body-sm {
  font-family: $font-family !important;
  font-weight: $medium-font-weight !important;
  font-size: $small-font-size !important;
  line-height: 104% !important;
}

// PRE TITLE
@mixin pre-title {
  font-family: $font-family;
  font-weight: $semi-bold-font-weight;
  font-size: $small-font-size;
  letter-spacing: 4%;
}

// Button text
@mixin button-text {
  font-family: $font-family !important;
  font-weight: $bold-font-weight !important;
  font-size: $small-font-size !important;
  line-height: 125% !important;
  letter-spacing: 2% !important;
}

// Link
@mixin link {
  font-family: $font-family !important;
  font-weight: $semi-bold-font-weight !important;
  font-size: $link-font-size !important;
  line-height: 140% !important;
  text-decoration: underline !important;
}

// Disclaimer
@mixin disclaimer {
  font-family: $font-family;
  font-weight: $base-font-weight;
  font-size: $xsmall-font-size;
  text-transform: uppercase;
  letter-spacing: 4%;
}

@mixin flex-align-right {
  display: flex;
  justify-content: flex-end;
}

@mixin flex {
  display: flex;
}

@mixin flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

@mixin flex-align-center {
  display: flex;
  align-items: center;
}

@mixin flex-justify-center {
  display: flex;
  justify-content: center;
}

@mixin flex-justify-between {
  display: flex;
  justify-content: space-between;
}

@mixin flex-justify-around {
  display: flex;
  justify-content: space-around;
}

@mixin flex-column {
  display: flex;
  flex-direction: column;
}

@mixin flex-column-justify-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@mixin flex-row {
  display: flex;
  flex-direction: row;
}

@mixin center {
  margin: auto;
}

@mixin vertically-center {
  margin-top: auto;
  margin-bottom: auto;
}

@mixin horizontally-center {
  margin-left: auto;
  margin-right: auto;
}
