@import '~@ng-select/ng-select/themes/material.theme.css';
html,
body {
  height: 100%;
}

h1 {
  font-family: "Inter", sans-serif !important;
  font-weight: 600 !important;
  font-size: 24px !important;
}

h2 {
  font-family: "Inter", sans-serif !important;
  font-weight: 500 !important;
  font-size: 24px !important;
  line-height: 125% !important;
}

h3 {
  font-family: "Inter", sans-serif !important;
  font-weight: 600 !important;
  font-size: 20px !important;
  line-height: 140% !important;
}

h4 {
  font-family: "Inter", sans-serif !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 140% !important;
}

body {
  font-family: "Inter", sans-serif !important;
  font-size: 14px !important;
  line-height: 1.42857143;
  color: #606060;
  margin: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

button {
  cursor: pointer;
}

p {
  margin-top: 0;
}

textarea {
  resize: none !important;
}

table th .mat-sort-header-container {
  display: flex;
  justify-content: center;
}

.no-wrap {
  white-space: nowrap;
}

.wrap {
  white-space: pre-line;
}

.center {
  text-align: center;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.column {
  display: flex;
  flex-direction: column;
}

.space-between {
  display: flex;
  justify-content: space-between;
}

@media print {
  .dontPrint {
    visibility: hidden !important;
    display: none !important;
  }
}
@media print {
  html {
    height: auto;
    scroll-behavior: smooth;
  }
}