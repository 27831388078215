$desktop-begins: 1000px;
$tablet-begins: 600px;

@mixin breakpoint($point) {
  @if $point == tablet {
    @media (max-width: $desktop-begins) {
      @content;
    }
  } @else if $point == mobile {
    @media (max-width: $tablet-begins) {
      @content;
    }
  } @else if $point == print {
    @media print {
      @content;
    }
  } @else {
    @content;
  }
}
